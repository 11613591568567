import './jquery.nice-select.js';
import './flatpickr.js';
import './flatpickr-uk.js';

$(".nice-select").niceSelect();
flatpickr(".datepicker", {
    locale: "uk",
    minDate: "today"
});

flatpickr(".timepicker", {
    enableTime: true,
    noCalendar: true,
    time_24hr: true
});

/*
const swiper = new Swiper('.swiper', {
    // Optional parameters
    direction: 'vertical',
    loop: true,

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
        el: '.swiper-scrollbar',
    },
});
console.log(swiper);*/


function menuHeight() {
    let totalValue = $(window).height() + $(window).scrollTop();
    let winHeight = window.innerHeight;
    let elementTop = $('footer').offset().top;
    let headerHeight = $('header').height();

    if ($(window).width() > 1100) {
        if (totalValue >= elementTop) {
            console.log(headerHeight);
            $("#mySidenav").css({"height": winHeight - (totalValue - elementTop) - headerHeight});
            // $("#mySidenav").css({"top": "unset", "bottom": totalValue - elementTop});
        } else if (totalValue < elementTop) {
            $("#mySidenav").css({"height": "", "top": ""});

        }
    }
};

$(document).ready(function () {
    let $bottom = $('footer');

    // $("#mySidenav").css({"top": $('.header-nav').height()});

    /*if($(window).width()<=1100){
        $('#mySidenav').addClass("no-active-nav");
    }*/
    // menuHeight();
    /*$(document).on('scroll', function(){
     //   menuHeight();

    });*/

    //вызов триггера для селекта количество
    $('.option_in_cat').trigger('change');

    $('#megamenu-toggle').click(function () {
        $(this).toggleClass('active');
        $('#overlay-megamenu').toggleClass('open');
        /* toggle - menus */
        if (!$('#mySidenav').hasClass("no-active-nav")) {
            $('#mySidenav').addClass("no-active-nav");
        }
    });

    $('.mySidenav-catalog-button').on('click', () => {
        $('#overlay-megamenu').toggleClass('open');
        if (!$('#mySidenav').hasClass("no-active-nav")) {
            $('#mySidenav').addClass("no-active-nav");
        }
    })



    $('.checkout-order-payment-tabs .vi-button').each(function (key, val) {
        if ($(val).hasClass('active')) {
            var index = $(val).index();
            $('.checkout-order-payment-content .checkout-order-payment__line').eq(index).addClass('active');
            $('.checkout-order-payment-content .checkout-order-payment__line').eq(index).find('input').removeAttr('disabled');
        }
    });
});


$(document).on('click', '.nav-open, .mobile-menu', function (e) {
    if ($('#mySidenav').hasClass("no-active-nav")) {
        $('#mySidenav').removeClass("no-active-nav");

        /* toggle - menus */
        $('#megamenu-toggle').removeClass('active');
        $('#overlay-megamenu').removeClass('open');


    } else {
        $('#mySidenav').addClass("no-active-nav");
    }
    e.stopPropagation();
    e.preventDefault();
});

$(document).on('click', '.closebtn', function (e) {
    $('#mySidenav').addClass("no-active-nav");
    e.stopPropagation();
    e.preventDefault();
});

$(document).on('click', '.filter-popup', function (e) {
    $('.popuptext').hide();
    $(this).children('.popuptext').show();
    e.stopPropagation();
});
$(document).on('click', 'body', function () {
    $('.popuptext').hide();//скрытие пдсказки при клике за пределами
    $('.search-prompt').hide();//скрытие выпадашки


});

const catalogFiltersMenuShow = () => {
    const $btnFilters = $('.catalog-page__filters-menu').find('.button-filters');
    const $btnFiltersItem = $('.filters-container');
    const $btnSelected = $('.catalog-page__filters-menu').find('.button-selected');
    const $btnSelectedItem = $('.vi-catalog-filters-result');

    const showFilters = () => {
        $btnFiltersItem.css({
            display: 'block'
        })
        $btnSelectedItem.css({
            display: 'none'
        })
        $btnFilters.addClass('filters-menu-button-active');
        $btnSelected.removeClass('filters-menu-button-active');
    }

    const showSelected = () => {
        $btnSelectedItem.css({
            display: 'block'
        })
        $btnFiltersItem.css({
            display: 'none'
        })
        $btnFilters.removeClass('filters-menu-button-active');
        $btnSelected.addClass('filters-menu-button-active');
    }

    $btnFilters.on('click', (e) => {
        if($btnFilters.hasClass('filters-menu-button-active')) {
            $btnFiltersItem.css({
                display: 'none'
            })
            $btnFilters.removeClass('filters-menu-button-active');
        } else {
            showFilters();
        }
    });
    $btnSelected.on('click', () => {
        if($btnSelected.hasClass('filters-menu-button-active')) {
            $btnSelectedItem.css({
                display: 'none'
            })
            $btnSelected.removeClass('filters-menu-button-active');
        } else {
            showSelected();
        }
    })
};


/*** filter ****/
$(document).on('click', '.vi-catalog-filters div .filter, .vi-catalog-filters-result .filter', function () {
    var url = $(this).data('href');
    $.pjax.reload({
        container: "#catalog-pjax", // контейнер, в котором надо обновить данные.
        // url: window.location.href,
        url: url,
        timeout: 0,
        //data: $('#master-filter').serialize(),
        success: function () {
            // slickCarousel();
            /*  if ( $('.pagination-ul').length == 0) {
                  $(".load-more-block").css('display','none');
              }*/
        }
    })
});
$(document).on('ready pjax:end', function(event) {
    catalogFiltersMenuShow();
})

$(document).on('click', '.hidden-similar-products', function () {
    var url = window.location;
    var id = $(this).data('id');
    $.ajax({
        type: 'GET',
        url: url + '?page=' + $(this).data('page') + '&work_id=' + id,
        dataType: 'html',
        beforeSend: function () {
            $('#work-pjax' + id + ' .hidden-similar-products').fadeOut(200);
        },
        success: function (html) {

            $('#work-pjax' + id + ' .hidden-similar-products').remove();

            $('#work-pjax' + id).append($(html).find('#work-pjax' + id + ' .similar-product').hide().fadeIn(500));
            $('#work-pjax' + id).append($(html).find('#work-pjax' + id + ' .hidden-similar-products').hide().fadeIn(500));
        }
    });
})

$(document).on('change', '#preferences-sort', function () {
    var url = $(this).val();
    $.pjax.reload({
        container: "#catalog-pjax", // контейнер, в котором надо обновить данные.
        // url: window.location.href,
        url: url,
        timeout: 0,
        //data: $('#master-filter').serialize(),
        success: function () {
            if (localStorage.getItem('display') == 'list') {
                $('.btn-list').trigger('click');

            } else if (localStorage.getItem('display') == 'tile') {
                $('.btn-tile').trigger('click');

            } else {
                $('.btn-grid').trigger('click');
                //$('#grid-view').addClass('active');
            }
            // slickCarousel();
            /*  if ( $('.pagination-ul').length == 0) {
                  $(".load-more-block").css('display','none');
              }*/
        }
    })
});

$(document).on('pjax:success', function (event, data, status, xhr, option) {
    if (typeof initMap != "undefined") {
        initMap();
    }

    if (option.container == '#catalog-pjax') {
        $('.unit-row').each(function (key, val) {
            var id = $(val).data('id');
            recalcUnit($('#units_' + id + ' input[type=\'radio\']:checked'))
        })
        if (localStorage.getItem('display') == 'list') {
            $('.btn-list').trigger('click');

        } else if (localStorage.getItem('display') == 'tile') {
            $('.btn-tile').trigger('click');

        } else {
            $('.btn-grid').trigger('click');
            //$('#grid-view').addClass('active');
        }
    }
})

/*** end filter ****/

/**** ajax form *****///
function submitAjaxData(form) {
    $.ajax({
        'data': $(form).serialize(),
        'dataType': 'html',
        'success': function (data) {

            $($(form).attr('id') + '-modal').modal('hide');

        },
        'type': 'post',
        'url': $(form).attr('action')
    });

};

$(document).on('submit', '#callback-form, #callback-form-full', function (e) {
    e.preventDefault();
    submitAjaxData(this);
    return false;
});
/****** end ajax form ****/


/***** search ****/
$(document).on('click', '.search-prompt', function (e) {
    e.stopPropagation();//стоп всплытие при клике на любом элементе выпадашки
}).on('click', '#site-search', function (e) {//при фокусе в поле поиска
    $('#site-search').trigger('keyup');
});

$(document).on('keyup', '#site-search', function (e) {
    if ($(this).val().length > 2) {
        var q = $("#form-search")
        $.ajax({
            type: $("#form-search").attr('method'),
            url: $("#form-search").attr('action'),
            data: q.serialize(),
            dataType: 'html',
            before: function () {
                $('.search-prompt').hide();
                $('.search-prompt').html('');
            },
            success: function (res) {
                $('.search-prompt').html(res);
                $('.search-prompt').show();

            },
            error: function (e) {
                console.log('Error!' + e);

            }
        });
    }
});
$(document).ready(function () {
    if ($('.content_block').height() < 90) {
        $('.content_toggle').trigger('click');
        $('.content_toggle').hide();
    }
    if (localStorage.getItem('display') == 'list') {
        $('.btn-list').trigger('click');

    } else if (localStorage.getItem('display') == 'tile') {
        $('.btn-tile').trigger('click');

    } else {
        $('.btn-grid').trigger('click');
        //$('#grid-view').addClass('active');
    }
    if (typeof jsonProduct != 'undefined') {
        $('.unit-row input[name=\'quantity\']').trigger('input');
    } else {
        //recalcUnit($('.unit-row input[type=\'radio\']:checked'));
        $('.unit-row').each(function (key, val) {
            var id = $(val).data('id');
            recalcUnit($('#units_' + id + ' .unit-container input[type=\'radio\']:checked'))
        })
    }

    //обрати обраний транспорт в оформленні замовлення
    $('.checkout-order-list__item .data-hidden').each(function (key, val) {
        $(val).parent().children('.display-value').val($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').text());//input
        $(val).parent().children('.display-value').html($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').html());//span
        //$(this).parent().parent().children('.data-hidden').val($(this).data('value'))
        //$(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').trigger('click');
    })


});
$(document).on('click', '.content_toggle', function () {
    $('.content_block').toggleClass('hide-text');
    if ($('.content_block').hasClass('hide-text')) {
        $('.content_toggle').removeClass('up');
        $('.content_toggle').addClass('down');
    } else {
        $('.content_toggle').removeClass('down');
        $('.content_toggle').addClass('up');
    }
    return false;
});
/**** end search ***/

// megamenu

$(document).on('mouseenter', '.menu-tablinks', function (e) {
    var tab = $(this).data('tab');
    menuTab(e, tab);
});

function menuTab(evt, tabName) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("menu-tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("menu-tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
}


/**** кастомізований селект дропдаун+інпут/спан *****/
$(document).on('click', '.select-control .dropdown-item', function () {
    $(this).parent().parent().children('.display-value').val($(this).text());//input
    $(this).parent().parent().children('.display-value').html($(this).html());//span
    $(this).parent().parent().children('.data-hidden').val($(this).data('value')).trigger('input').trigger('change');

});
$(document).on('input', '.select-control .display-value', function () {
    $(this).next().val($(this).val()).trigger('input').trigger('change');
});

/**** end кастомізований селект дропдаун+інпут/спан ****/

$(document).on('click', '.view-type-btn', function () {
    $('.site-catalog').removeClass('view-type-tile').removeClass('view-type-list').removeClass('view-type-grid').addClass('view-type-' + $(this).data('viewtype'));
    $('.view-type-btn').removeClass('active');
    $(this).addClass('active')
    localStorage.setItem('display', $(this).data('viewtype'));
    //$('.site-catalog').;
});
$(document).on('click', '.hiddenphones', function () {
    if ($(this).hasClass('active')) {
        $(this).removeClass('active');
    } else {
        $(this).addClass('active');
    }
});

$(document).on('click', '.q-plus', function () {
    var step = parseInt($(this).data('step'));
    var id = $(this).data('id');
    //$(this).parent().find('input[name=\'quantity\']').val(parseInt($(this).parent().find('input[name=\'quantity\']').val())+step);//.trigger('input');
    $('#quantity' + id).val(parseInt($('#quantity' + id).val()) + step);
    $('#quantity' + id).trigger('input');
    // $('.unit-row input[name=\'quantity\']').val(parseInt($('.unit-row input[name=\'quantity\']').val())+step);
    //$('.unit-row input[name=\'quantity\']').trigger('input');
})
$(document).on('click', '.q-minus', function () {
    var step = parseInt($(this).data('step'));
    var id = $(this).data('id');
    var res = parseInt($('#quantity' + id).val()) - step;
    if (res < step) res = step;

    $('#quantity' + id).val(res);
    $('#quantity' + id).trigger('input');
})

$(document).on('change', '.unit-row .unit-container input[type=\'radio\']', function () {
    //alert($(this).data('ratio'))
    recalcUnit($(this))
});

$(document).on('input', '.unit-row input[name=\'quantity\']', function () {
    //alert($(this).data('ratio'))
    $(this).val($(this).val().replace(/,/, '.'))
    var id = $(this).data('id');
    recalcUnit($('#units_' + id + ' .unit-container input[type=\'radio\']:checked'))
});

$(document).on('change', 'input[name=\'price_type\']', function () {


    var id = $('.unit-row input[name=\'quantity\']').data('id');
    jQuery.cookie('price_type', $('input[name=\'price_type\']:checked').val(), {path: '/'});
    recalcUnit($('#units_' + id + ' .unit-container input[type=\'radio\']:checked'))
});
$(document).on('change', 'input[name=\'shipping\']', function () {
    jQuery.cookie('shipping', $('input[name=\'shipping\']:checked').val(), {path: '/'});
    var id = $('.unit-row input[name=\'quantity\']').data('id');
    recalcUnit($('#units_' + id + ' .unit-container input[type=\'radio\']:checked'));
});

/**
 * форматування ціни відповідно до формату.
 * Заміна коми на крапку
 * @param number
 * @returns {string}
 */
function currencyFormat(number) {
    return new Intl.NumberFormat('ua-UK',
        {
            style: 'currency',
            currency: 'UAH',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            currencyDisplay: 'narrowSymbol'
        }).format(number)
        .replace(',', '.');
}

/**
 * пеперахунок одиниць виміру і цін відносно основної одиниці до обраної
 * прорахунок кількості машин, загальної ваги, суми при зміні кількості
 * вибір оптимального транспорту (вазі) в картці товару
 *
 * визивається при змані одиниці виміру, кількості, типу ціни, типу доставки (транспорт, самовивіз)
 * @param obj
 */
function recalcUnit(obj) {
    var id = $(obj).data('id');

    var price_type = $('input[name=\'price_type\']:checked').val();
    var text = '<ul>';
    var select_price = $(obj).data('price');
    var select_max_price = $(obj).data('max-price');

    var select_avg_price = $(obj).data('avg-price');
    var select_avg_price_vat = $(obj).data('avg-price-vat');

    var select_price_vat = $(obj).data('price-vat');
    var select_max_price_vat = $(obj).data('max-price-vat');

    var select_type = $(obj).data('type');
    var unit_cost = '<ul>';
    var current_type = '';
    var calc_result = {};

    $('#units_' + id + ' .unit-container input[type=\'radio\']:not(:checked)').each(function (key, val) {
        //переклад в одиниці для кожного не checked відносно checked елементу
        var res = ($(val).data('ratio') * $('#units_' + id + ' input[name=\'quantity\']').val() / $(obj).data('ratio')).toFixed(1);
        var round = Math.round(res);	//округлення до цілого для подальшого аналізу
        current_type = $(val).data('type'); //тип одиниць виміру поточного елементу

        if ((round / res) == 1) { //як що на виході ціле, видаємо ціле,
            //пеервірка що результат не дав 0, та на тип одиниць, інакше немає сенсу відображати
            if (res != 0 && current_type != select_type) {
                calc_result[current_type] = '<li>' + round + ' ' + $(val).data('val') + '</li>';
            }
        } else { //інакше зі знаком пілся коми
            if (res != 0 && current_type != select_type) {
                calc_result[current_type] = '<li>' + res + ' ' + $(val).data('val') + '</li>';
            }
        }
    });
    //збираємо у список варіанти перерахунків в інщі одиниці
    $.each(calc_result, function (key, val) {
        text += val;
    })
    text += '</ul>';
    //$('#price_' + id).next().html(text);	//'.calc-result-val'
    $('#price_' + id).closest('.price-part').find('.calc-result-val').html(text);	//'.calc-result-val'
    $('#price_unit_' + id).closest('.price-part').find('.calc-result-val').html(text);	//'.calc-result-val'


    //загальна вартість в обраній розмірності
    var total_price = currencyFormat(parseFloat((select_price / $(obj).data('ratio')) * $('#units_' + id + ' input[name=\'quantity\']').val())) + '/' + $(obj).data('val');
    var total_price_vat = currencyFormat(parseFloat((select_price_vat / $(obj).data('ratio')) * $('#units_' + id + ' input[name=\'quantity\']').val())) + '/' + $(obj).data('val');
    $('#price_' + id + ' span:first').html((price_type == 1) ? total_price_vat : total_price);
    var total_max_price = currencyFormat(parseFloat((select_max_price / $(obj).data('ratio')) * $('#units_' + id + ' input[name=\'quantity\']').val())) + '/' + $(obj).data('val');
    var total_max_price_vat = currencyFormat(parseFloat((select_max_price_vat / $(obj).data('ratio')) * $('#units_' + id + ' input[name=\'quantity\']').val())) + '/' + $(obj).data('val');
    //$('#price_' + id + ' span:last').html((price_type == 1) ? total_max_price_vat : total_max_price);

    var total_avg_price = currencyFormat(parseFloat((select_avg_price / $(obj).data('ratio')) * $('#units_' + id + ' input[name=\'quantity\']').val())) + '/' + $(obj).data('val');
    var total_avg_price_vat = currencyFormat(parseFloat((select_avg_price_vat / $(obj).data('ratio')) * $('#units_' + id + ' input[name=\'quantity\']').val())) + '/' + $(obj).data('val');
    $('#price_' + id + ' span:first').html((price_type == 1) ? total_avg_price_vat : total_avg_price);

    /////для картки товару прораховує кількість машин та загальну вагу
    if (typeof jsonProduct != 'undefined') {
        // cars,jsonProduct - глобальна змінна в json форматі, передається зі сторінки товару. містить інфу про транспорт
        var count_cars = [];
        var current_weight = $('#units_' + id + '  input[name=\'quantity\']').val() * jsonProduct.weight / $(obj).data('ratio'); //перерахунок в основну одиницю
        var current_volume = current_weight / ((jsonProduct.ton_place) ? jsonProduct.ton_place : 1);
        $.each(cars, function (key, val) {
            //кількість машин
            var res = Math.ceil(current_weight / val.weight);
            var res_volume = Math.ceil(current_volume / val.volume);
            count_cars.push({'id': val.id, 'val': Math.max(res, res_volume)});
        })

        $('.count-shipping-val span').html(findBy(count_cars, 'val', minComp).val); //кількість машин
        $('.count-shipping-weight span').html((current_weight < 1) ? current_weight.toFixed(2) : current_weight.toFixed(1)); //загальна вага в перерахунку в основну одиницю

        //знаходимо першу мінімальну кількість транспорту, робимо вибір в селекті транспорту
        $('.select-car .dropdown-item[data-value=\'' + findBy(count_cars, 'val', minComp).id + '\']').trigger('click');
    }

}

/**
 * пошук по обэкту
 * @param arr
 * @param key
 * @param comparatorFn
 * @returns {*}
 */
function findBy(arr, key, comparatorFn) {
    return arr.reduce(function (prev, curr, index, arr) {
        return comparatorFn.call(arr, prev[key], curr[key]) ? prev : curr;
    });
}

function minComp(prev, curr) {
    return prev <= curr;
}

function maxComp(prev, curr) {
    return prev >= curr;
}

/*****/


(function ($) {
    $.cart = function () {

    }
    $.cart.order = function (id, quantity, url) {
        //alert(url);

        var c_data = {
                'quantity': quantity,
                'unit_id': $('input[name=\'unit' + id + '\']:checked').val()
            }
        ;

        jQuery.cookie('p' + id, JSON.stringify(c_data), {path: '/'});

        window.open(url, '_blank');


    }
})(jQuery);

$(document).on('click', '.del_car', function () {
    changeCar($(this).children('input'))
})

$(document).on('change', '.checkout-order-list__item .data-hidden', function () {
    changeCar($(this))
})

/**
 * зміна транспорту
 * @param obj
 */
function changeCar(obj) {
    var url = $('input[name=\'car_url\']').val();
    $.ajax({

        'url': url,
        'data': $('#checkout-form').serialize() + '&' + $.param(obj),
        'type': 'post',
        'dataType': 'json',
        'success': function (data) {

            $(data.cart).find('.content-cart-case.catalog-cart-page').replaceAll('.content-cart-case.catalog-cart-page');
            $(data.cart).find('.vi-cart-sidebar__bottom-part').replaceAll('.vi-cart-sidebar__bottom-part');
            $(data.cart).find('.content-cart-case.main-page-case').replaceAll('.content-cart-case.main-page-case');

            $(data.checkout).find('.checkout-order-all-conteiner').replaceAll('.checkout-order-all-conteiner');
            $(data.checkout).find('.checkout-order-all--final').replaceAll('.checkout-order-all--final');

            //обрати обраний транспорт в оформленні замовлення
            $('.checkout-order-list__item .data-hidden').each(function (key, val) {
                $(val).parent().children('.display-value').val($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').text());//input
                $(val).parent().children('.display-value').html($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').html());//span
                //$(this).parent().parent().children('.data-hidden').val($(this).data('value'))
                //$(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').trigger('click');
            })
            flatpickr(".datepicker", {
                locale: "uk",
                minDate: "today"
            });

            flatpickr(".timepicker", {
                enableTime: true,
                noCalendar: true,
                time_24hr: true
            });
        }
    })
}


/**
 * видалення з кошика/замовлення
 * оновлює бокову панель кошику та сторінку замовлення
 */
$(document).on('click', '.order-widget__cart-items .close, .checkout-order-list__item-actions .close, .checkout-order-top__delete', function () {
    var url = $('input[name=\'delete_url\']').val();
    $.ajax({

        'url': url,
        'data': $('#checkout-form').serialize() + '&' + $.param({
            order_id: $(this).data('order-id'),
            product_id: $(this).data('product-id'),
            order_product_id: $(this).data('item-id'),
        }),
        'type': 'post',
        'dataType': 'json',
        'success': function (data) {

            $(data.cart).find('.content-cart-case.catalog-cart-page').replaceAll('.content-cart-case.catalog-cart-page');
            $(data.cart).find('.vi-cart-sidebar__bottom-part').replaceAll('.vi-cart-sidebar__bottom-part');
            $(data.cart).find('.content-cart-case.main-page-case').replaceAll('.content-cart-case.main-page-case');

            $(data.checkout).find('.checkout-order-all-conteiner').replaceAll('.checkout-order-all-conteiner');
            $(data.checkout).find('.checkout-order-all--final').replaceAll('.checkout-order-all--final');

            //обрати обраний транспорт в оформленні замовлення
            $('.checkout-order-list__item .data-hidden').each(function (key, val) {
                $(val).parent().children('.display-value').val($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').text());//input
                $(val).parent().children('.display-value').html($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').html());//span
                //$(this).parent().parent().children('.data-hidden').val($(this).data('value'))
                //$(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').trigger('click');
            })
            flatpickr(".datepicker", {
                locale: "uk",
                minDate: "today"
            });

            flatpickr(".timepicker", {
                enableTime: true,
                noCalendar: true,
                time_24hr: true
            });
        }
    })
});

/**
 * зміна кількості товару в замовленні/кошику
 * оновлює бокову панель кошику та сторінку замовлення
 */
$(document).on('input', '.cart-widget input[name=\'quantity\'], .checkout-order input[name=\'quantity\'] ', function () {
    //alert($(this).data('ratio'))
    $(this).val($(this).val().replace(/,/, '.'))
    var id = $(this).data('id');
    var url = $('input[name=\'update_url\']').val();
    $.ajax({

        'url': url,
        'data': $('#checkout-form').serialize() + '&' + $.param({
            order_id: $(this).data('order-id'),
            product_id: $(this).data('product-id'),
            order_product_id: $(this).data('item-id'),
            quantity: $(this).val()
        }),
        'type': 'post',
        'dataType': 'json',
        'success': function (data) {
            $(data.cart).find('.content-cart-case.catalog-cart-page').replaceAll('.content-cart-case.catalog-cart-page');
            $(data.cart).find('.vi-cart-sidebar__bottom-part').replaceAll('.vi-cart-sidebar__bottom-part');
            $(data.cart).find('.content-cart-case.main-page-case').replaceAll('.content-cart-case.main-page-case');

            $(data.checkout).find('.checkout-order-all-conteiner').replaceAll('.checkout-order-all-conteiner');
            $(data.checkout).find('.checkout-order-all--final').replaceAll('.checkout-order-all--final');
            //обрати обраний транспорт в оформленні замовлення
            $('.checkout-order-list__item .data-hidden').each(function (key, val) {
                $(val).parent().children('.display-value').val($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').text());//input
                $(val).parent().children('.display-value').html($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').html());//span
                //$(this).parent().parent().children('.data-hidden').val($(this).data('value'))
                //$(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').trigger('click');
            })
            flatpickr(".datepicker", {
                locale: "uk",
                minDate: "today"
            });

            flatpickr(".timepicker", {
                enableTime: true,
                noCalendar: true,
                time_24hr: true
            });
        }
    })

});

/**
 * вибір замовлень/доставок до оформлення
 * оновлює сторінку замовлення
 */
$(document).on('click', 'input[name=\'CheckoutForm[to_order][]\']', function () {
    var url = $('input[name=\'check_url\']').val();
    $.ajax({

        'url': url,
        'data': $('#checkout-form').serialize() + '&' + $.param($('input[name=\'CheckoutForm[to_order][]\']:checked')),
        'type': 'post',
        'dataType': 'json',
        'success': function (data) {

            $(data.checkout).find('.checkout-order-all-conteiner').replaceAll('.checkout-order-all-conteiner');
            $(data.checkout).find('.checkout-order-all--final').replaceAll('.checkout-order-all--final');
            //обрати обраний транспорт в оформленні замовлення
            $('.checkout-order-list__item .data-hidden').each(function (key, val) {
                $(val).parent().children('.display-value').val($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').text());//input
                $(val).parent().children('.display-value').html($(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').html());//span
                //$(this).parent().parent().children('.data-hidden').val($(this).data('value'))
                //$(val).parent().find('.dropdown-item[data-value=\'' + $(val).val() + '\']').trigger('click');
            })
            flatpickr(".datepicker", {
                locale: "uk",
                minDate: "today"
            });

            flatpickr(".timepicker", {
                enableTime: true,
                noCalendar: true,
                time_24hr: true
            });
        }
    })
});

/******* side panel for gmaps  ****/

(function ($) {

    var _self = {};

    $.fn.sidePanel = function (options) {
        var settings = {};
        var dataObject = {};

        _self = this;
        options = options || {};
        $.extend(settings, options);
        //$(_self).fadeOut(400);
        $.fn.sidePanel.clear(settings);
        $.fn.sidePanel.create(settings);
        //$(_self).fadeIn(200);
    }
    $.fn.sidePanel.create = function (s) {
        if (s.data.error && !s.data.tariffs.length) {
            var error = '<div id="provider" class="card provider_wraper row">' + s.data.error + '</div>';
            $(_self).append(error);
            return;
        }
        //хедер таблиці
        var head = '';
        if (s.data.shipping == 'shipping') {
            head = '<div class="locations-result__heading-grid">';
            head += '<div><h6>' + s.lang.head_manufacturer + '</h6></div><div><h6>' + s.lang.head_service + '</h6></div>';
            head += '<div><h6>' + s.lang.head_price + '</h6></div><div><h6>' + s.lang.head_total + '</h6></div></div>';
        }
        $(_self).append(head);
        $.each(s.data.tariffs, function (key, provider) {
            //console.log(provider)
            $.each(provider.seller, function (key_seller, seller) {
                if (s.data.is_manager) { //manager
                    $.each(seller.carrier, function (key_carrier, carrier) {
                        var offer = $.fn.sidePanel.__item_offer(provider, seller, carrier, s);
                        var provider_obj = $(offer);
                        $(provider_obj).bind('click', {

                            carrier_id: carrier.id,
                            provider_id: provider.provider_id,
                            company_id: seller.company_id,
                            //hash:seller.encode,
                            route_map: provider,
                            conteiner: _self,
                            end_location: s.end,
                            waypoints: provider.waypoints,
                            //car_info:car,
                            directionsService: s.directionsService,
                            directionsRenderer: s.directionsRenderer,
                        }, $.fn.sidePanel.providerClick);

                        $(provider_obj).find('button').bind('click', {
                            hash: carrier.encode,
                            product_id: carrier.product_id,
                            order_url: s.order_url
                        }, $.fn.sidePanel.addToCart)
                        $(_self).append(provider_obj);
                    })

                } else { //customer
                    var offer = $.fn.sidePanel.__item_offer(provider, seller, seller.carrier[0], s);
                    var provider_obj = $(offer);
                    $(provider_obj).bind('click', {

                        carrier_id: seller.carrier[0].id,
                        provider_id: provider.provider_id,
                        company_id: seller.company_id,
                        hash: seller.encode,
                        route_map: provider,
                        conteiner: _self,
                        end_location: s.end,
                        waypoints: provider.waypoints,
                        //car_info:car,
                        directionsService: s.directionsService,
                        directionsRenderer: s.directionsRenderer,
                    }, $.fn.sidePanel.providerClick);
                    $(provider_obj).find('button').bind('click', {
                        hash: seller.carrier[0].encode,
                        product_id: seller.carrier[0].product_id,
                        order_url: s.order_url
                    }, $.fn.sidePanel.addToCart)
                    $(_self).append(provider_obj);
                    return false;
                }

            });
        });

        $('#provider' + s.data.optimum.provider_id + 'seller' + s.data.optimum.seller_id + 'car' + s.data.optimum.carrier_id).addClass('optimum_provider');
        if (s.data.selected) {
            $('#provider' + s.data.selected.provider_id + 'seller' + s.data.selected.seller_id + 'car' + s.data.selected.carrier_id).addClass('activeProvider ');
        }

    }
    $.fn.sidePanel.__item_offer = function (provider, seller, carrier, s) {
        //пропозиції
        if (s.data.shipping == 'shipping') {
            var offer = '<div id="provider' + provider.provider_id + 'seller' + seller.company_id + 'car' + carrier.id + '" class="widget-locations-result__single widget-locations-result__single--with-delivery">';
            offer += '<div class="locations-result__single--grid" >';

            offer += '<div class="manufacturer-info">';
            offer += '<div class="manufacturer"><img src="' + provider.manufacturer_ico + '" alt="" class="manufacturer-logo"><p class="manufacturer-name">' + s.lang.manufacturer + ' ' + provider.manufactirer + '</p></div>';
            offer += '<div class="location-items-route"><p class="route-from">' + provider.address + '</p>';
            offer += '<p class="route-to">' + provider.end_address + '</p></div>';
            if (s.data.is_manager) {
                offer += '<div class="delivery-person"><p>' + s.lang.seller + ': ' + seller.company_name + '</p><p>' + s.lang.carrier + ': ' + carrier.name + '</p></div>';
            }
            offer += '</div>';
            offer += '<div class="prices-total-grid">';
            offer += '<div class="prices-total-row">';
            offer += '<div><p class="product-label">' + s.lang.product + '</p></div>';
            offer += '<div><p>' + carrier.price_unit + ' ' + s.lang.uah + '/' + carrier.select_unit + '</p></div>';
            offer += '<div><p>' + carrier.total_price + ' ' + s.lang.uah + '</p></div>';
            offer += '</div>';
            offer += '<div class="prices-total-row">';
            offer += '<div><p class="delivery-label">' + s.lang.shipping + '</p></div>';
            offer += '<div><p>' + carrier.shipping_of_unit + ' ' + s.lang.uah + '/' + carrier.select_unit + '</p></div>';
            offer += '<div><p>' + carrier.shipping_price + ' ' + s.lang.uah + '</p></div>';
            offer += '</div>';
            offer += '<div class="prices-total-row">';
            offer += '<div><p class="total-label">' + s.lang.total + '</p></div>';
            offer += '<div><p class="blue-total">' + carrier.total_of_unit + ' ' + s.lang.uah + '/' + carrier.select_unit + '</p></div>';
            offer += '<div><p class="blue-total">' + carrier.total + ' ' + s.lang.uah + '</p></div>';
            offer += '</div>';
            offer += '</div>';

            /*
              offer+='<div class="buttons"><span>'+s.lang.show_route+'</span><button class="vi-button">'+s.lang.add_to_order+'</button></div>';

             */
            offer += '</div>';

            offer += '<div class="locations-result__total">';
            offer += '<div class="left-part">';
            offer += '<p>' + s.lang.number_of_deliveries + ' <span class="delivery-number">' + carrier.count_cars + '</span> ' + s.lang.delivery_distance + ' <span class="direction-nuumber"><b>' + provider.distance + '</b> ' + s.lang.distance_unit + '</span></p>';
            offer += '</div>';
            offer == '<div class="right-part">';
            // offer += '<p class="alert-text">' + s.lang.can_loaded + '</p>';
            offer += '<button class="vi-button vi-button--transparent">' + s.lang.add_to_order + '</button>';
            offer += '</div>';
            offer += '</div>';

            /*
            if(s.data.shipping=='shipping') {
                        offer += '<div class="col">' + s.lang.one_shipping + ' - ' + carrier.shipping_once + ' ' + s.lang.uah + '</div>';
                        offer += '<div class="col">' + s.lang.number_of_deliveries + ' ' + seller.count_cars + '</div>';
                    }
             */

            offer += '</div>';
        } else {
            var offer =
                '<div id="provider' + provider.provider_id + 'seller' + seller.company_id + 'car' + carrier.id + '" class="widget-locations-result__single widget-locations-result__single--self-delivery">';
            offer += '<div>' +
                        '<div class="manufacturer">';
            offer +=        '<img src="' + provider.manufacturer_ico + '" alt="" class="manufacturer-logo">';
            offer +=        '<p class="manufacturer-name">' + s.lang.manufacturer + ' ' + provider.manufactirer + '</p>';
            offer +=    '</div>' +
                    '</div>';
            offer += '<div class="locations-result__single--self-grid">';
            offer +=    '<div class="self-grid-left"><div>';
            offer +=        '<p>' + s.lang.address_for_pickup + '</p>';
            offer +=        '<p class="delivery-address">' + provider.address + '</p>';
                                if (s.data.is_manager) {
                                    offer += '<div class="delivery-person"><p>' + s.lang.seller + ': ' + seller.company_name + '</p></div>';
                                }
            offer +=    '</div></div>';
            offer +=    '<div class="self-grid-rigth"><div><p>' + s.lang.hours_of_work + '</p><p>' + provider.time + '</p></div>';
            offer +=    '<div><p>' + s.lang.Distance + '</p><p>' + provider.distance + ' ' + s.lang.distance_unit + '</p></div>';
            offer +=    '<div><p>' + s.lang.product_price + '</p><p class="delivery-price">' + carrier.price_unit + ' ' + s.lang.uah + '/' + carrier.select_unit + '</p></div>';
            offer +=    '<div><p>' + s.lang.the_sum_together + '</p><p class="delivery-total">' + carrier.total_price + ' ' + s.lang.uah + '</p></div>';
            offer +=    '<div><button class="vi-button vi-button--lightblue">' + s.lang.add_to_order + '</button></div>';
            offer +=    '</div></div>';
            offer += '</div>';
        }
        return offer;
    }
    $.fn.sidePanel.clear = function (s) {
        $(_self).html('');
    }
    $.fn.sidePanel.providerClick = function (e) {
        e.stopPropagation();

        //обраний варыант
        var selected = {
            location: e.data.route_map.start_location,
            carrier_id: e.data.carrier_id,
            provider_id: e.data.provider_id,
            seller_id: e.data.company_id,
        };

        displayRoute(e.data.route_map.start_location, e.data.end_location, directionsService, directionsRenderer, selected);
        //$(this).parent().parent().parent().parent().find('.provider_wraper').removeClass('activeProvider');
        $(this).parent().find('.widget-locations-result__single').removeClass('activeProvider');

        //$(this).parent().parent().parent().find('.provider_wraper').removeClass('activeCar');
        //$(this).parent().parent().parent().addClass('activeProvider');
        $(this).addClass('activeProvider');
        //$(this).addClass('activeCar');
        //$(this).parent().parent().addClass('activeProvider');

    }

    $.fn.sidePanel.addToCart = function (e) {
        e.stopPropagation();
        $.ajax({

            'url': e.data.order_url,
            'data': {hash: e.data.hash, product_id: e.data.product_id},
            'type': 'post',
            'dataType': 'html',
            'success': function (data) {
                //              console.log(data);
                //$(e.target).parent().parent().trigger('click');
                $(data).replaceAll('.vi-cart-sidebar'); // перерисовывает всю боковую панель
                window.resetCatalogMenu();
                window.cloneCatalogMenu();
            }
        })
        //console.log(e.data);
    }
    $.fn.sidePanel.carOrder = function (e) {

    }

})(jQuery);


/******* end side panel for gmaps  ****/


(function ($) {
    var _self = {};
    $.fn.tabs = function () {
        _self = this;
        $(_self).on('click', '.tab button', function () {
            $('.tablinks').removeClass('active');
            $(this).addClass('active');
            $('.tabcontent').removeClass('active');
            $('#' + $(this).data('content')).addClass('active');
        })
    }
    //головне меню в боковій панелі віджет \frontend\widgets\MenuWidget
    $(document).on('click', '.helpMenuDropdown', function (e) {
        if ($(this).next().hasClass('show')) {
            $(this).next().toggle('hide');
            $(this).next().removeClass('show');
            e.preventDefault();
            return;
        }
        $(this).parent().parent().find('.helpMenuDropdown').each(function (key, val) {
            if ($(val).next().hasClass('show')) {
                $(val).next().toggle('hide');
                $(val).next().removeClass('show');
            }
        })
        $(this).next().toggle('show');
        $(this).next().addClass('show');
        e.preventDefault();
    })

})(jQuery);


/******* бокова панель, логін, чекаут *****/


$(document).on('click', '.checkout-page-user-tabs__item', function () {
    $(".checkout-page-user-tabs__item").removeClass("active");
    $(this).addClass("active");
    $(".checkout-page-user").removeClass("active");
    var index = $(this).index();
    $(".checkout-page-user").eq(index).addClass("active");
}).on('click', '.cart-authorization-title__close', function () {
    $(".cart-authorization").removeClass("active");
    // $(".top-cart-case-registration").removeAttr("disabled");
}).on('click', '.top-cart-case-login', function () {
    if ($('.cart-authorization--login').hasClass('active')) {
        $('#login-form').trigger('submit');
    }
    $(".cart-authorization").removeClass("active");
    $(".cart-authorization--login").addClass("active");
    //$(".top-cart-case-registration").removeAttr("disabled");
}).on('click', '.top-cart-case-registration', function () {
    if ($('.cart-authorization--registration').hasClass('active')) {
        $('#signup-form').trigger('submit');
    }
    $(".cart-authorization").removeClass("active");
    $(".cart-authorization--registration").addClass("active");
    //$(".top-cart-case-registration").attr("disabled", "disabled");
}).on('click', '.checkout-order-services-toggler', function () {
    $(this).toggleClass("active");
    $(".checkout-order-services-content").toggleClass("active");
}).on('click', '.top-cart-case-activation', function () {
    if ($('.cart-authorization--activation').hasClass('active')) {
        // $('#active-form').trigger('submit');
    }
    $(".cart-authorization").removeClass("active");
    $(".cart-authorization--activation").addClass("active");
    //$(".top-cart-case-registration").attr("disabled", "disabled");
});

$(document).on('click', '.checkout-order-payment-tabs .vi-button', function () {
    $('.checkout-order-payment-tabs .vi-button').removeClass('active');
    $('.checkout-order-payment-content .checkout-order-payment__line').removeClass('active');
    //$('.checkout-order-payment-content input').attr('disabled', 'disabled');

    $(this).addClass('active');
    var index = $(this).index();
    $('.checkout-order-payment-content .checkout-order-payment__line').eq(index).addClass('active');
    $('.checkout-order-payment-content .checkout-order-payment__line').eq(index).find('input').removeAttr('disabled');
});


$(document).on('submit', '#login-form, #signup-form, #active-form, #login-form-ch', function (e) {
    e.preventDefault();
    var form = this;
    $.ajax({
        'data': $(this).serialize(),
        'dataType': 'json',
        'success': function (data) {
            if (data.error) {
                $.each(data.error, function (key, msg) {
                    $(form).find('.field-' + key + ' .invalid-feedback').html(msg[0]).show();
                    $(form).find('#' + key).addClass('is-invalid');
                    /** на випадок, де поля форми знаходяться за обгорткою форми та звязаны по атрибуту поля form
                     * цей підхід використовуэться як що треба зробити форму в формі.
                     * Yii валідація при втраті фокуса з поля не працює, але відповідь валідації можна розкидати відносно батьківського контейнера
                     *<form id="login-form-ch" action="" method="post" style="display:none;">
                     *    <input type="hidden" name="_csrf" value="">
                     *</form>
                     * <input type="text" id="loginform-identity" class="" name="" form="login-form-ch" >
                     */
                    if ($(form).find('#' + key).length == 0) {
                        $(form).parent().find('.field-' + key + ' .invalid-feedback').html(msg[0]).show();
                        $(form).parent().find('#' + key).addClass('is-invalid');
                    }
                })
            } else {
                if (data.status) {
                    $(".cart-authorization").removeClass("active");
                    $('.case-unregistered ').hide();

                    $('.cart-authorization--activation').addClass('active');

                } else {
                    $('.case-registered-user .user-name').html(data.name);
                    $(".cart-authorization").removeClass("active");
                    $(".cart-authorization--activation").removeClass("active");
                    $('.case-registered-user').addClass('active');
                    //$('.cart-authorization--login').removeClass('active');
                    $('.case-unregistered').hide();
                }
            }
            // $($(this).attr('id') + '-modal').modal('hide');

        },
        'type': 'post',
        'url': $(this).attr('action')
    });
    return false;
});


$(document).on('click', '.logout-link', function (e) {
    e.preventDefault()
    $.ajax({
        'dataType': 'json',
        'success': function (data) {
            $('.case-registered-user').removeClass('active');
            $('.case-unregistered').show();
        },
        'type': 'post',
        'url': $(this).attr('href')
    });

    return false;
})

/******* end бокова панель, логін, чекаут *****/


/*-----------Личный кабинет - Профиль--------------- */
$(document).ready(function () {
    // общий контейнер профильной странички
    var $userPCont = $('.user-profile-container');

    var $legInfoSelect = $userPCont.find('#userprofile-legalinfo')
    $legInfoSelect.on('change', () => {
        switch ($legInfoSelect.val()) {
            case "2": {
                $('.user-profile-legal-info .buyer-cells').each((index, element) => {
                    $(element).show();
                    $(element).find('input').prop('disabled', false);
                })
                break;
            }
            case  "1": {
                $('.user-profile-legal-info .buyer-cells').each((index, element) => {
                    $(element).hide();
                    $(element).find('input').prop('disabled', true);
                })
                break;
            }
        }
    });
    $legInfoSelect.change();

    // файл загрузчик
    $(document).on('change', '.file-input', function () {

        var $getOneFileItemContainer = (fileName, removeCallback) => {
            var $content =
                $(`<li class="list-group-item user-profile-uploaded-fileitem">
					 <img src="/img/file-uploaded.svg" alt="">
						  <span>${fileName}</span>
					 <img src="/img/x-circle.svg" alt="" class="user-profile-fileitem-remove">
            	</li>`);
            $content.find('.user-profile-fileitem-remove').on('click', () => {
                $content.remove();
                removeCallback(fileName);
            });
            console.log($content)
            return $content;
        }

        var $fileListContainer = $('.user-profile-uploaded-fileslist');

        var selectedFiles = $(this)[0].files;
        for (var i = 0; i < selectedFiles.length; i++) {
            $fileListContainer.append($getOneFileItemContainer(selectedFiles[i].name, (fileNameRemoved) => {
                //console.log(fileNameRemoved)
                const dt = new DataTransfer()
                for (let j = 0; j < selectedFiles.length; j++) {
                    const file = selectedFiles[j]
                    if (file.name != fileNameRemoved) {
                        dt.items.add(file) // here you exclude the file. thus removing it.
                    }
                }
                selectedFiles = dt.files
                //console.log("After remove one file: ")
                //console.dir(selectedFiles)
            }));
        }
    });
})


$(document).ready(function () {
    // Закрытие мега меню
    $('.megamenu-btn-close').on('click', () => {
        $("#overlay-megamenu").toggleClass("open")
    });


    // Переключатели страницы товара
    var $shipItemsContainer = $('.shipping-items');						// их пара контейнеров на странице
    $shipItemsContainer.each((index, oneCont) => {				// перебираю по одной
        //
        var $first = $(oneCont).find('label').first();
        var $last = $(oneCont).find('label').last();

        $first.on('click', (e) => {
            $first.addClass('selected-shipping-item')
            $last.removeClass('selected-shipping-item')
        });
        $last.on('click', (e) => {
            $last.addClass('selected-shipping-item')
            $first.removeClass('selected-shipping-item')
        })
    })


    // Кастомизированный загрузчик файлов
    // куда:  user-profile-file-drop-area
    // что: upload-kit-input
    $(window).on('resize', function () {
        var $dest = $('.user-profile-file-drop-area');
        var $source = $('.upload-kit-input');

        $source.width($dest.innerWidth());
        $source.height($dest.innerHeight());
        // $source.css('top', $dest.offset().top + 'px');
        // $source.css('left', $dest.offset().left + 'px');
    });


    /*
        Каталог под списком не отображать на маленьких устройствах
    * */
    /*$(window).on('resize', function(){
        var windWidth = $(window).width()
        if(windWidth <= 1100) {
            $('.control-group .btn-grid').click();
        }
    });*/
    if ($(window).width() <= 1100) {
        $('.control-group .btn-grid').click();
    }
    $('.view-type-tile').find('.unit-container').each((index, uCont) => {
        var sUnits = $(uCont).find('.single-unit');
        if (sUnits.length < 4) {
            $(uCont).append($('<div>', {
                class: 'single-unit',
                html: ''
            }))
        }
    })
    $('.view-type-grid').find('.unit-container').each((index, uCont) => {
        var sUnits = $(uCont).find('.single-unit');
        if (sUnits.length < 4) {
            $(uCont).append($('<div>', {
                class: 'single-unit',
                html: ''
            }))
        }
    })
    catalogFiltersMenuShow();

// mobile interactive menu --------------------------------------------------------------------------------------start

    let $destProfile = $('#profile-form-modal .modal-body');
    let $sourceProfile = $('.vi-cart-sidebar__top-part');
    let $profileCopy = $sourceProfile.clone(true);

    let isMovingCatalog = false;
    let isMovingMobile = false;
    let isMovingCallback = false;
    let isSmallScreen = false;

    window.cloneCatalogMenu = () => {
        isMovingCatalog = true;
        let $dest = $('#basket-form-modal .modal-body');
        let $source = $('.catalog-cart-page');
        $dest.empty();
        $dest.append($source.clone(true));
        $dest.addClass('vi-cart-sidebar__content-part');
        $dest.append($('.vi-cart-sidebar__bottom-part').clone(true));
    };

    window.resetCatalogMenu = () => {
        isMovingCatalog = false;
        let $dest = $('#basket-form-modal .modal-body');
        $dest.empty();
        $('.catalog-cart-page').appendTo('.original-catalog-container'); // вернем элемент на исходное место
        $('.vi-cart-sidebar__bottom-part').appendTo('.original-footer-container'); // вернем элемент на исходное место
    };

    let cloneMobileMenu = () => {
        isMovingMobile = true;
        $destProfile.empty();
        $destProfile.append($profileCopy);
        $sourceProfile.empty();
    };

    let resetMobileMenu = () => {
        isMovingMobile = false;
        $destProfile.empty();
        $sourceProfile.append($profileCopy);
    };

    let cloneCallbackMenu = () => {
        isMovingCallback = true;
        let $dest = $('#callback-form-modal .modal-body');
        let $source = $('.site-sidebar .widget-contacts');
        $dest.empty();
        $dest.append($source.clone(true));
        $source.empty();
    }
    let resetCallbackMenu = () => {
        isMovingCallback = false;
        let $source = $('#callback-form-modal .modal-body .widget-contacts');
        let $dest = $('.site-sidebar .widget-contacts');
        $dest.empty();
        $dest.append($source.clone(true));
        $source.empty();
    }

    window.handleResize = () => {
        if ($(window).width() <= 1100) {
            if (!isSmallScreen) {
                if (!isMovingMobile) cloneMobileMenu();
                if (!isMovingCatalog) window.cloneCatalogMenu();       // basket
                if (!isMovingCallback) cloneCallbackMenu();
                isSmallScreen = true;
            }
        } else {
            if (isSmallScreen) {
                if (isMovingMobile) resetMobileMenu();
                if (isMovingCatalog) window.resetCatalogMenu();
                if (isMovingCallback) resetCallbackMenu();
                isSmallScreen = false;
            }
        }
    };

    $(window).on('resize', window.handleResize);
    window.handleResize();
// mobile interactive menu --------------------------------------------------------------------------------------end
})

String.prototype.format = function (params) {
    params = params || {};
    params = {
        digitsCount: params.digitsCount || 3,
        digitsSeparator: params.digitsSeparator || ' ',
        decimalCount: params.decimalCount || 0,
        decimalSeparator: params.decimalSeparator || '.',
        decimalReplacer: params.decimalReplacer || '0',
    };
    var str = this.replace(/\s+/g, '');
    if (!str) str = '0';
    var int = str.replace(/\.\d+/, '');
    var re = new RegExp('\\B(?=(\\d{' + params.digitsCount + '})+(?!\\d))', 'g');
    int = int.replace(re, params.digitsSeparator);
    var output = int;
    if (params.decimalCount) {
        var dec = str.replace(/\d+\./, '');
        dec = dec.substr(0, params.decimalCount);
        if (dec.length < params.decimalCount) dec = dec + params.decimalReplacer.repeat(params.decimalCount - dec.length);
        output = output + params.decimalSeparator + dec;
    }
    return output;
};


